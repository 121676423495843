import { ref } from "@vue/composition-api";

import { BasePagination } from "@/shared/types/components/Base/BasePagination";

export const PAGE_SIZE = 12;

export function usePagination() {
  const pagination = ref<BasePagination>({
    pageSize: PAGE_SIZE,
    pageNumber: 0,
  });

  function changePage(page: number) {
    pagination.value.pageNumber = page;
  }

  return {
    pagination,
    changePage,
  };
}
