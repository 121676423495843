





















import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";

import ChangeLog from "@/app/components/ChangeLog/ChangeLog.vue";
import CommentUnits from "@/app/components/Comment/CommentUnits.vue";
import { encodeAttachmentInput } from "@/models/AttachmentInput";
import { DEFAULT_COMMENT_INPUT_EXT } from "@/shared/constants/components/Task/TaskNewComment";
import { EntityTypeEnum } from "@/shared/enums/components/Comment/CommentsWrapper";
import { useRoute, useRouter } from "@/shared/hooks";
import { useNotification } from "@/shared/hooks/notification";
import { usePagination } from "@/shared/hooks/pagination";
import {
  createProjectComment,
  createTaskComment,
  fetchCommentPageByCommentId,
  fetchProjectComments,
  fetchTaskComments,
  removeComment, updateComment,
} from "@/shared/requests/comments";
import { EntityInfo } from "@/shared/types/components/Base/commentsWrapper";
import { CommentInputExt } from "@/shared/types/components/Base/CommentUnit";
import {
  CommentPage,
  CreateProjectCommentQueryVariables, CreateTaskCommentQueryVariables,
  GetCommentPageQuery, GetProjectCommentPageQueryVariables, GetTaskCommentPageQueryVariables,
} from "@/shared/types/schema.types";

interface CommentData extends Object {
  comments: GetCommentPageQuery["commentPage"]["comments"];
  sheetInfo: GetCommentPageQuery["commentPage"]["sheetInfo"]
}

interface CommentActions {
  fetchEntityComments: (params: GetTaskCommentPageQueryVariables |
    GetProjectCommentPageQueryVariables) => Promise<CommentPage>
  addEntityComment: (params: CreateProjectCommentQueryVariables | CreateTaskCommentQueryVariables ) => Promise<number>
}

export default defineComponent({
  name: "TaskComments",
  props: {
    entityInfo: {
      type: Object as PropType<EntityInfo>,
      default: null,
    },
  },
  components: {
    ChangeLog,
    CommentUnits,
  },
  setup(props) {
    const COMMENTS_PAGE_SIZE = 50;
    const { errNotification, successNotification } = useNotification();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const commentId = route.query.id;
    const { pagination, changePage } = usePagination();

    pagination.value.pageSize = COMMENTS_PAGE_SIZE;

    const commentPage = ref<CommentData>({
      comments: [],
      sheetInfo: {
        pageNumber: 0,
        pageSize: COMMENTS_PAGE_SIZE,
        totalPages: 0,
        totalElements: 0,
      },
    });
    const commentInput = ref<CommentInputExt>(_.cloneDeep(DEFAULT_COMMENT_INPUT_EXT));

    const commentActions: Record<EntityTypeEnum, CommentActions> = {
      [EntityTypeEnum.TASK]: {
        fetchEntityComments: params => fetchTaskComments(params),
        addEntityComment: params => createTaskComment(params),
      },
      [EntityTypeEnum.PROJECT]: {
        fetchEntityComments: params => fetchProjectComments(params),
        addEntityComment: params => createProjectComment(params), 
      },
    };

    const formattedParams = computed(() => ({
      pageSize: pagination.value.pageSize,
      commentInput: {
        ..._.omit(commentInput.value, ["mode", "id"]),
        attachmentInputs: encodeAttachmentInput(commentInput.value.attachmentInputs),
      },
    }));

    async function clearQuery() {
      if (!route.query.commentId) return;
      await router.replace({ query: { params: [] } });
    }

    // Добавить коммент
    async function addComment() {
      try {
        if (!props.entityInfo) return;
        const pageNumber = await commentActions[props.entityInfo.entityType]["addEntityComment"]({
          ...formattedParams.value,
          id: props.entityInfo?.id,
        });
        successNotification('Комментарий добавлен');
        commentInput.value = _.cloneDeep(DEFAULT_COMMENT_INPUT_EXT);
        await clearQuery();
        await changeCommentPage(pageNumber);
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }
    // Отредактировать выбранный коммент
    async function editComment() {
      try {
        if (!commentInput.value.id) {
          throw new Error('У комментария отсутсвует ID');
        }
        const pageNumber = await updateComment({
          ...formattedParams.value,
          commentId: commentInput.value.id,
        });
        successNotification('Комментарий отредактирован');
        commentInput.value = _.cloneDeep(DEFAULT_COMMENT_INPUT_EXT);
        await changeCommentPage(pageNumber);
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }
    // Удалить выбранный коммент
    async function deleteComment(commentId: string) {
      try {
        const pageNumber = await removeComment({
          commentId,
          pageSize: pagination.value.pageSize,
        });

        successNotification('Комментарий удален');
        await clearQuery();
        await changeCommentPage(pageNumber);
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    async function changeCommentPage(pageNumber: number) {
      pagination.value.pageNumber = pageNumber;
      return fetchComments();
    }

    async function fetchComments(commentId?: string) {
      try {
        if (!props.entityInfo) return;
        loading.value = true;
        if (commentId) {
          commentPage.value = await fetchCommentPageByCommentId({
            commentId: commentId as string,
            pageSize: COMMENTS_PAGE_SIZE,
          });
        }
        else {
          const params = {
            id: props.entityInfo?.id,
            pagination: pagination.value,
          };
          commentPage.value = await commentActions[props.entityInfo.entityType]["fetchEntityComments"](params);
        }
      } catch (err) {
        errNotification(err);
      }
      finally {
        loading.value = false;
      }
    }

    onMounted(async() => {
      await fetchComments(commentId as string);
    });

    return {
      commentPage,
      editComment,
      deleteComment,
      changePage,
      addComment,
      loading,
      commentInput,
      changeCommentPage,
      encodeAttachmentInput,
    };
  },
});
