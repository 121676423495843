




















import { defineComponent, PropType } from "@vue/composition-api";

import Pagination from "@/app/components/Base/Pagination.vue";
import ChangeLogItem from "@/app/components/ChangeLog/ChangeLogItem.vue";
import { ChangeLog } from "@/shared/types/schema.types";

export default defineComponent({
  name: "TaskChangeLog",
  components: {
    ChangeLogItem,
    Pagination,
  },
  props: {
    changeLog: {
      type: Object as PropType<ChangeLog>,
      default: () => null,
    },
  },
  setup() {

    return {  };
  },
});
