































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { VueEditor } from "vue2-editor";

import Dropzone from "@/app/components/Base/Dropzone.vue";
import { DEFAULT_COMMENT_INPUT } from "@/shared/constants/components/Task/TaskNewComment";
import { AttachmentItemData } from "@/shared/types/components/Base/AttachmentItem";
import { CommentInput } from "@/shared/types/schema.types";

export type CommentInputAttachExt = Omit<CommentInput, "attachmentInputs"> & { attachmentInputs: AttachmentItemData[] };

export default defineComponent({
  name: "CommentForm",
  components: {
    VueEditor,
    Dropzone, 
  },
  props: {
    value: {
      type: Object as PropType<CommentInputAttachExt>,
      default: () => (_.cloneDeep(DEFAULT_COMMENT_INPUT)),
    },
    title: {
      type: String,
      default: 'Новый комментарий',
    },
  },
  setup(props, { emit }) {
    const disabled = computed(() => !props.value?.body);
  
    function onInputComment(text: string) {
      emit('input', {
        ...props.value,
        body: text, 
      });
    }

    function clearComment() {
      emit('input', {
        ...props.value,
        body: '', 
      });
    }

    function addComment() {
      emit('add-comment');
    }

    return {
      onInputComment,
      clearComment,
      addComment,
      disabled,
    };
  },
});
