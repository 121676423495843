















































































import { computed, defineComponent, PropType } from "@vue/composition-api";

import {
  changeLogEntity,
  changeLogOperationName, commentOperationsWithLink,
  updatableOperations,
} from "@/shared/constants/components/ChangeLog/ChangeLogItem/ChangeLogDictionaries";
import { useRoute } from "@/shared/hooks";
import {
  ChangeLogRecord,
  ChangeOperationCode,
  LoggableEntity,
  LoggableEntityCode,
} from "@/shared/types/schema.types";
import { DISPLAY_DATE_FORMAT_WITH_TIME, formatDateTimeForDisplay, stripTags } from "@/shared/utils";

type Key = Extract<keyof ChangeLogRecord, "loggableEntity" | "curValue" | "prevValue">;

interface FormatData {
  link?: string;
  text: string
}

export default defineComponent({
  name: "ChangeLogItem",
  props: {
    changeLogItem: {
      type: Object as  PropType<ChangeLogRecord>,
      default: undefined,
    },
  },
  setup(props) {
    const COMMENT_MAX_LENGTH = 50;
    const route = useRoute();
    const projectId = route.params.id;
    const taskId = route.params.taskId;

    const formattedPrevValue = computed(() => formatValue(props.changeLogItem, 'prevValue'));
    const formattedCurValue = computed(() => formatValue(props.changeLogItem, 'curValue'));
    const formattedEventValue = computed(() => formatEventName(props.changeLogItem));
    const updatableValuesAreShown = computed(() => {
      if (!props.changeLogItem) return false;
      return updatableOperations.includes(props.changeLogItem.operationCode);
    });

    function formatTextValue(entity: LoggableEntity): string {
      const text = entity["text"] || 'не указано';
      if (entity["code"] === LoggableEntityCode.UnixTimestamp) {
        return formatDateTimeForDisplay(text);
      }
      else if (entity["code"] === LoggableEntityCode.Comment) {
        const strippedText = stripTags(text);
        return strippedText.length > COMMENT_MAX_LENGTH? `${strippedText.slice(0, COMMENT_MAX_LENGTH)  }...` : strippedText;
      }
      return text;
    }

    function formatEventName(changeLogItem: ChangeLogRecord) {
      const targetVal = changeLogItem["loggableEntity"];
      if (!targetVal || !targetVal["code"]) return {
        code: '-',
        textData: {
          text: '',
          link: undefined, 
        },
        operationName: '-',
      };
      return {
        code: changeLogEntity[targetVal["code"]],
        textData: formatValue(changeLogItem, 'loggableEntity'),
        operationName: changeLogOperationName[changeLogItem.operationCode], 
      };
    }

    function formatValue(changeLogItem: ChangeLogRecord, key: Key): FormatData {
      const targetVal = changeLogItem[key];
      if (!targetVal) return { text: 'не указано' };
      switch (key) {
        case "loggableEntity": {
          switch (targetVal["code"]) {
            case LoggableEntityCode.Task: {
              const needLink = ((changeLogItem.operationCode === ChangeOperationCode.UpdateTaskParentTask) && (taskId !== targetVal["entityId"]));
              return {
                text: formatTextValue(targetVal),
                link: needLink? projectId? `/projects/${projectId}/tasks/${targetVal["entityId"]}` : `/tasks/${targetVal["entityId"]}` : undefined,
              };
            }
            case LoggableEntityCode.Comment: {
              let link: undefined | string;
              if (commentOperationsWithLink.includes(changeLogItem.operationCode)) {
                if (!taskId && projectId) {
                  link = `/projects/${projectId}?commentId=${targetVal["entityId"]}`;
                } else if (taskId && projectId) {
                  link = `/projects/${projectId}/tasks/${taskId}?commentId=${targetVal["entityId"]}`;
                }
                else if (taskId && !projectId) {
                  link = `/tasks/${taskId}?commentId=${targetVal["entityId"]}`;
                }
              }
              return {
                text: formatTextValue(targetVal),
                link,
              };
            }
            default: {
              return {
                text: formatTextValue(targetVal),
                link: undefined, 
              };
            }
          }
        }
        case "curValue": {
          switch (targetVal["code"]) {
            case LoggableEntityCode.Task: {
              const needLink = ((changeLogItem.operationCode === ChangeOperationCode.UpdateTaskParentTask) && (taskId !== targetVal["entityId"]));
              return {
                text: formatTextValue(targetVal),
                link: needLink? projectId? `/projects/${projectId}/tasks/${targetVal["entityId"]}` : `/tasks/${targetVal["entityId"]}` : undefined,
              };
            }
            default: {
              return {
                text: formatTextValue(targetVal),
                link: undefined, 
              };
            }
          }
        }
        case "prevValue": {
          switch (targetVal["code"]) {
            case LoggableEntityCode.Task: {
              const needLink = ((changeLogItem.operationCode === ChangeOperationCode.UpdateTaskParentTask) && (taskId !== targetVal["entityId"]));
              return {
                text: formatTextValue(targetVal),
                link: needLink? projectId? `/projects/${projectId}/tasks/${targetVal["entityId"]}` : `/tasks/${targetVal["entityId"]}` : undefined,
              };
            }
            default: {
              return {
                text: formatTextValue(targetVal),
                link: undefined,
              };
            }
          }
        }
      }
    }

    return {
      updatableValuesAreShown,
      formattedEventValue,
      formattedPrevValue,
      formattedCurValue,
      formatDateTimeForDisplay,
      DISPLAY_DATE_FORMAT_WITH_TIME,
      changeLogOperationName,
      changeLogEntity,
      updatableOperations,
    };
  },
});
