import { CommentModeEnum } from "@/shared/enums/components/Comment/CommentUnit";
import { CommentInputExt } from "@/shared/types/components/Base/CommentUnit";
import { CommentInput } from "@/shared/types/schema.types";

export const DEFAULT_COMMENT_INPUT: CommentInput = {
  attachmentInputs: [],
  body: "",
};

export const DEFAULT_COMMENT_INPUT_EXT: CommentInputExt = {
  attachmentInputs: [],
  body: "",
  mode: CommentModeEnum.create,
  id: null,
};
