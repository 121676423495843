





























import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "Pagination",
  model: {
    prop: "page",
    event: "change",
  },
  props: {
    page: {
      type: Number as PropType<number>,
      default: 1, 
    },
    total: {
      type: Number as PropType<number>,
      required: true, 
    },
  },
  setup(props, { emit }) {
    const current = computed(() => props.page? props.page + 1 : 1);

    const items = computed(() => {
      let items = [];

      if (props.total <= 12) {
        for (let i = 1; props.total >= i; i++) {
          items.push(i);
        }
      }
      else if (current.value < 4) {
        items = [1, 2, 3, 4, "...", props.total];
      }
      else if (current.value < props.total - 2) {
        items = [
          1,
          current.value - 3 >= 2 ? "..." : false,
          current.value - 2,
          current.value - 1,
          current.value,
          current.value + 1,
          current.value + 2,
          current.value + 3 <= props.total - 1 ? "..." : false,
          props.total,
        ];
      }
      else {
        items = [1, "...", props.total - 3, props.total - 2, props.total - 1, props.total];
      }
      return items.filter((item) => !!item);
    });

    function prev(): void {
      if (current.value > 1) {
        emit("change", props.page - 1);
      }
    }

    function next(): void {
      if (current.value < props.total) {
        emit("change", props.page + 1);
      }
    }

    function itemClick(item: string | number): void {
      if (typeof item === "number") {
        emit("change", item - 1);
      }
    }

    return {
      current,
      items,
      prev,
      next,
      itemClick,
    };
  },
});
