import { ChangeOperationCode, LoggableEntityCode } from "@/shared/types/schema.types";

export const changeLogOperationName: Record<ChangeOperationCode, string> = {
  [ChangeOperationCode.CreateTask]: "создана",
  [ChangeOperationCode.UpdateTaskCoexecutor]: "изменен соисполнитель задачи",
  [ChangeOperationCode.ArchiveProject]: "заархивирован проект",
  [ChangeOperationCode.ArchiveTask]: "заархивирована",
  [ChangeOperationCode.CreateProject]: "создан",
  [ChangeOperationCode.UnzipProject]: "разархивирован проект",
  [ChangeOperationCode.UnzipTask]: "разархивирована задача",
  [ChangeOperationCode.UpdateProjectCoexecutor]: "изменен соисполнитель проекта",
  [ChangeOperationCode.UpdateProjectDeadline]: "изменен срок завершения проекта",
  [ChangeOperationCode.UpdateProjectDescription]: "изменено описание проекта",
  [ChangeOperationCode.UpdateProjectDescriptionAttachments]: "изменены вложения к проекту",
  [ChangeOperationCode.UpdateProjectExecutor]: "изменен исполнитель проекта",
  [ChangeOperationCode.UpdateProjectName]: "изменено название проекта",
  [ChangeOperationCode.UpdateProjectPriority]: "изменен приоритет проекта",
  [ChangeOperationCode.UpdateProjectResult]: "изменен результат проекта",
  [ChangeOperationCode.UpdateProjectResultAttachments]: "изменены вложения к результату проекта",
  [ChangeOperationCode.UpdateProjectStatus]: "изменен статус проекта",
  [ChangeOperationCode.UpdateTaskDeadline]: "изменен срок завершения задачи",
  [ChangeOperationCode.UpdateTaskDescription]: "изменено описание задачи",
  [ChangeOperationCode.UpdateTaskDescriptionAttachments]: "изменены вложения к описанию задачи",
  [ChangeOperationCode.UpdateTaskExecutor]: "изменен исполнитель задачи",
  [ChangeOperationCode.UpdateTaskName]: "изменено описание задачи",
  [ChangeOperationCode.UpdateTaskParentTask]: "изменена родительская задача",
  [ChangeOperationCode.UpdateTaskPriority]: "изменен приоритет задачи",
  [ChangeOperationCode.UpdateTaskStatus]: "изменен статус задачи",
  [ChangeOperationCode.CreateComment]: "создан",
  [ChangeOperationCode.RemoveComment]: "удален",
  [ChangeOperationCode.RemoveProject]: "удален",
  [ChangeOperationCode.RemoveTask]: "удалено",
  [ChangeOperationCode.UpdateCommentBody]: "обновлен текст",
  [ChangeOperationCode.UpdateCommentAttachments]: "обновлены вложения",
};

export const changeLogEntity: Record<LoggableEntityCode, string> = {
  [LoggableEntityCode.Attachment]: "Вложение",
  [LoggableEntityCode.Comment]: "Комментарий",
  [LoggableEntityCode.Project]: "Проект",
  [LoggableEntityCode.Task]: "Задача",
  [LoggableEntityCode.Text]: "Текст",
  [LoggableEntityCode.UnixTimestamp]: "Метка времени",
  [LoggableEntityCode.User]: "Пользователь",
};

export const updatableOperations = [
  ChangeOperationCode.UpdateTaskCoexecutor,
  ChangeOperationCode.UpdateProjectCoexecutor,
  ChangeOperationCode.UpdateProjectDeadline,
  ChangeOperationCode.UpdateProjectDescription,
  ChangeOperationCode.UpdateProjectExecutor,
  ChangeOperationCode.UpdateProjectName,
  ChangeOperationCode.UpdateProjectPriority,
  ChangeOperationCode.UpdateProjectResult,
  ChangeOperationCode.UpdateProjectStatus,
  ChangeOperationCode.UpdateTaskDeadline,
  ChangeOperationCode.UpdateTaskDescription,
  ChangeOperationCode.UpdateTaskExecutor,
  ChangeOperationCode.UpdateTaskName,
  ChangeOperationCode.UpdateTaskParentTask,
  ChangeOperationCode.UpdateTaskPriority,
  ChangeOperationCode.UpdateTaskStatus,
];

export const commentOperationsWithLink = [
  ChangeOperationCode.CreateComment,
  ChangeOperationCode.UpdateCommentBody,
  ChangeOperationCode.UpdateCommentAttachments,
];
