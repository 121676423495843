












































import { defineComponent, onMounted, PropType, ref, watch } from "@vue/composition-api";

import Modal from "@/app/components/Base/Modal.vue";
import Pagination from "@/app/components/Base/Pagination.vue";
import CommentForm from "@/app/components/Comment/CommentForm.vue";
import CommentUnit from "@/app/components/Comment/CommentUnit.vue";
import { DEFAULT_COMMENT_INPUT_EXT } from "@/shared/constants/components/Task/TaskNewComment";
import { CommentModeEnum } from "@/shared/enums/components/Comment/CommentUnit";
import { useRoute } from "@/shared/hooks";
import { CommentInputExt } from "@/shared/types/components/Base/CommentUnit";
import { Comment, CommentPage } from "@/shared/types/schema.types";

export default defineComponent({
  name: "CommentUnits",
  components: {
    CommentUnit,
    CommentForm,
    Modal,
    Pagination,
  },
  props: {
    value: {
      type: Object as PropType<CommentInputExt>,
      default: null,
    },
    commentPage: {
      type: Object as unknown as PropType<CommentPage>,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const commentId = ref(route.query.commentId);
    function onEditComment(comment: Comment) {
      emit('input', {
        ... _.pick(comment, "body", "id"),
        mode: CommentModeEnum.edit,
        attachmentInputs: comment.attachments,
      });
    }
    const newComment = ref(_.cloneDeep(DEFAULT_COMMENT_INPUT_EXT));

    watch(
      () => props.value as CommentInputExt,
      (val) => {
        if (!val || val.mode === CommentModeEnum.edit) return;
        newComment.value = _.cloneDeep(val);
      },
    );

    onMounted(() => {
      if (commentId.value && _.isString(commentId.value)) {
        const el = document.getElementById(commentId.value);
        el?.scrollIntoView({
          behavior: "smooth",
          block: "center", 
        });
      }
    });

    return {
      commentId,
      onEditComment,
      CommentModeEnum,
      DEFAULT_COMMENT_INPUT_EXT,
      newComment, 
    };
  },
});
