












































































import { defineComponent, PropType } from "@vue/composition-api";

import Dropdown from "@/app/components/Base/Dropdown.vue";
import { useNotification } from "@/shared/hooks/notification";
import { Comment } from "@/shared/types/schema.types";
import { copyTextToClipboard, DISPLAY_DATE_FORMAT_WITH_TIME, formatDateTimeForDisplay } from "@/shared/utils";

export default defineComponent({
  name: "CommentUnit",
  components: { Dropdown },
  props: {
    comment: {
      type: Object as PropType<Comment>,
      default: null,
    },
  },
  setup(props) {
    const { errNotification, successNotification } = useNotification();

    function onCopyCommentLink() {
      if (!props.comment) return;
      copyTextToClipboard(`${window.location}?commentId=${props.comment.id}`, (text: string) => successNotification(text), (text: string) => errNotification(text));
    }
    return {
      formatDateTimeForDisplay,
      DISPLAY_DATE_FORMAT_WITH_TIME,
      copyTextToClipboard,
      onCopyCommentLink,
    };
  },
});
