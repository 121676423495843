import { apClient } from "@/app/core/gQL/apolloClient";
import {
  CommentPage,
  CreateProjectCommentDocument,
  CreateProjectCommentQuery,
  CreateProjectCommentQueryVariables,
  CreateTaskCommentDocument,
  CreateTaskCommentQuery,
  CreateTaskCommentQueryVariables,
  DeleteCommentDocument,
  DeleteCommentQuery,
  DeleteCommentQueryVariables,
  GetCommentPageDocument,
  GetCommentPageQuery,
  GetCommentPageQueryVariables,
  GetProjectCommentPageDocument,
  GetProjectCommentPageQuery,
  GetProjectCommentPageQueryVariables,
  GetTaskCommentPageDocument,
  GetTaskCommentPageQuery,
  GetTaskCommentPageQueryVariables,
  UpdateCommentDocument,
  UpdateCommentQuery,
  UpdateCommentQueryVariables,
} from "@/shared/types/schema.types";

export async function fetchTaskComments(
  params: GetTaskCommentPageQueryVariables,
): Promise<CommentPage> {
  const result = await apClient.get<GetTaskCommentPageQuery>(GetTaskCommentPageDocument, {
    variables: params,
  });
  return result.task.commentPage as CommentPage;
}

export async function fetchProjectComments(
  params: GetProjectCommentPageQueryVariables,
): Promise<CommentPage> {
  const result = await apClient.get<GetProjectCommentPageQuery>(GetProjectCommentPageDocument, {
    variables: params,
  });
  return result.project.commentPage as CommentPage;
}

export async function fetchCommentPageByCommentId(
  params: GetCommentPageQueryVariables,
): Promise<GetCommentPageQuery["commentPage"]> {
  const result = await apClient.get<GetCommentPageQuery>(GetCommentPageDocument, {
    variables: params,
  });
  return result.commentPage;
}

export async function removeComment(params: DeleteCommentQueryVariables): Promise<number> {
  const {
    comment: {
      removeComment: {
        commentPage: {
          sheetInfo: { pageNumber },
        },
      },
    },
  } = await apClient.set<DeleteCommentQuery, DeleteCommentQueryVariables>(
    DeleteCommentDocument,
    params,
  );
  return pageNumber;
}

export async function updateComment(params: UpdateCommentQueryVariables): Promise<number> {
  const {
    comment: {
      updateComment: {
        commentPage: {
          sheetInfo: { pageNumber },
        },
      },
    },
  } = await apClient.set<UpdateCommentQuery, UpdateCommentQueryVariables>(
    UpdateCommentDocument,
    params,
  );
  return pageNumber;
}

export async function createTaskComment(params: CreateTaskCommentQueryVariables): Promise<number> {
  const {
    task: {
      createComment: {
        commentPage: {
          sheetInfo: { pageNumber },
        },
      },
    },
  } = await apClient.set<CreateTaskCommentQuery, CreateTaskCommentQueryVariables>(
    CreateTaskCommentDocument,
    params,
  );
  return pageNumber;
}

export async function createProjectComment(
  params: CreateProjectCommentQueryVariables,
): Promise<number> {
  const {
    project: {
      createComment: {
        commentPage: {
          sheetInfo: { pageNumber },
        },
      },
    },
  } = await apClient.set<CreateProjectCommentQuery, CreateProjectCommentQueryVariables>(
    CreateProjectCommentDocument,
    params,
  );
  return pageNumber;
}
